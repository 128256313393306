<template>
  <v-app>
    <div style="position: fixed; height: 100vh; background-color: #222">
      <v-img src="@/assets/login.jpg" width="100vw" height="100vh" />
    </div>
    <v-container id="fundo" class="p-0 fill-height" fluid>
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="11" sm="8" md="5" lg="4" style="padding: 0px">
          <v-container :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
            <v-card color="white" style="padding: 12px">
              <v-container class="pb-2">
                <v-row
                  class="layout-logos-login fill-height px-4 height"
                  align="center"
                  justify="center"
                >
                  <v-img
                    class="logo-form-login educampo"
                    src="img/milk-plan.png"
                    height="120px"
                    contain
                  />
                </v-row>
                <v-row justify="center">
                  <strong style="font-size: 22px; padding: 6px; color: #333"
                    >Assist</strong
                  >
                </v-row>
                <v-row justify="center">
                  <span style="font-size: 22px; padding: 12px; color: #333"
                    >Criação de senha</span
                  >
                </v-row>
              </v-container>
              <v-container v-if="loadingData" class="pa-6">
                <v-row justify="center" class="pa-4">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="primary"
                    :indeterminate="true"
                  />
                </v-row>
                <v-row justify="center">
                  <h3>Verificando dados</h3>
                </v-row>
              </v-container>
              <div v-else-if="!loader">
                <v-row v-if="notFound" justify="center">
                  <span
                    style="
                      font-size: 18px;
                      padding: 12px;
                      color: rgb(224 22 22);
                      font-weight: 500;
                      text-align: center;
                    "
                    >Não foi possível verificar seu endereço de email. Entre em
                    contato com o suporte.</span
                  >
                </v-row>
                <v-form
                  v-else
                  id="form_login"
                  ref="form_login"
                  v-model="valid"
                  @submit.prevent="postLogin"
                >
                  <v-container class="p-4">
                    <v-row justify="center">
                      <span
                        style="
                          font-size: 18px;
                          padding: 0px;
                          color: rgb(1 160 21);
                          font-weight: 500;
                          text-align: center;
                        "
                        >Email verificado com sucesso! <br />
                        Agora defina uma senha para sua conta: <br />
                        {{ email }}
                      </span>
                    </v-row>
                    <v-text-field
                      v-model="password"
                      light
                      prepend-inner-icon="mdi-key"
                      :rules="[rules.required]"
                      type="password"
                      label="Senha"
                      counter
                      @input="$refs.form_login.validate()"
                    />
                    <v-text-field
                      v-model="confirmPassword"
                      light
                      prepend-inner-icon="mdi-key"
                      :rules="[rules.required, rules.passwordMatch]"
                      type="password"
                      label="Confirme sua senha"
                      counter
                      @change="$refs.form_login.validate()"
                    />
                    <v-row justify="center" class="pt-4">
                      <v-btn
                        color="success"
                        class="mr-4"
                        type="submit"
                        @click="createPassword"
                      >
                        Criar Senha
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
              <v-container v-else class="pa-6">
                <v-row justify="space-around">
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="primary"
                    :indeterminate="true"
                  />
                </v-row>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { login } from '../services/AuthService'
export default {
  props: {
    backgroundImage: {
      type: String,
      default: 'static/img/login.jpg',
    },
  },
  data() {
    return {
      loadingData: true,
      notFound: false,
      loader: false,
      email: null,
      password: null,
      confirmPassword: null,
      rules: {
        required: value => !!value || 'Obrigatório.',
        passwordMatch: value =>
          (this.password && value && this.password === value) ||
          'Senhas não conferem.',
      },
      valid: null,
    }
  },
  mounted() {
    if (this.$route.params.token) {
      this.api.auth.verifyEmailByToken(this.$route.params.token).then(data => {
        if (data) {
          this.email = data
        } else {
          this.notFound = true
        }
        this.loadingData = false
      })
    } else {
      this.$router.push('/login')
    }
  },
  methods: {
    createPassword() {
      this.loader = true
      this.api.auth
        .createPasswordByToken(
          this.$route.params.token,
          this.password,
          this.confirmPassword,
        )
        .then(
          data => {
            this.Swal.fire(
              'Sua senha foi criada!',
              'Você será redirecionado para a página inicial. Não esqueça sua senha.',
              'success',
            )
            login(data.user, data.access_token)
            this.$router.push('/')
          },
          () => {
            this.loader = false
          },
        )
    },
  },
}
</script>
<style scope lang="scss">
.senha {
  color: rgb(40, 57, 211);
}
.senha:hover {
  color: rgb(29, 115, 245);
}

.outline-login .container .v-input .v-input__control .v-input__slot {
  border: 2px solid #005bb9;
}

.logo-form-login.educampo .v-image__image--cover {
  background-size: contain;
  background-position: center !important;
}

.logo-form-login.sebrae .v-image__image--cover {
  background-size: contain;
  background-position: center !important;
}

html {
  overflow: auto;
}

.headline {
  font-family: 'Poppins', sans-serif !important;
}

.display-grid {
  display: grid;
  height: auto !important;
  .flex {
    background-color: white;
    padding: 7px;
    margin-bottom: 70%;
    .v-responsive.v-image {
      margin-top: 6px;
      padding: 0px 0.7px;
    }
  }
}

.apresentacao {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
}

.layout-login-row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.demonstracao span {
  font-size: 1rem !important;
  font-weight: bold;
  letter-spacing: 0.009375em !important;
  line-height: 1.357rem;
  font-family: 'Roboto', sans-serif !important;
  color: #fff;
}

.requisitar-demonstracao {
  font-size: 1.2rem !important;
  font-weight: bold;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem;
  font-family: 'Roboto', sans-serif !important;
  color: #4e5152;
}

@media (min-width: 41em) {
  #fundo {
    background-size: auto;
  }
} /* 656px */

@media (min-width: 47em) {
  #fundo {
    background-size: auto;
  }
} /* 752px */

@media (min-width: 68em) {
  #fundo {
    background-size: auto;
  }
} /* 1088px */

@media (min-width: 82em) {
  #fundo {
    background-size: auto;
  }
} /* 1312px */

@media (min-width: 100em) {
  #fundo {
    background-size: contain;
  }
  .layout-logos-login {
    align-items: center !important;
  }
  .tela-login.flex .v-card {
    height: 316px;
  }
  .headline {
    font-size: 34px !important;
  }
  .logo-form-login.educampo {
    height: 110px !important;
  }
  .logo-form-login.sebrae {
    height: 70px !important;
    padding-bottom: 20px;
  }
} /* 1600px */
</style>
